export const environment = {
  production: true,
  baseApiUrl: 'https://ivicon-matrix-api-dev.azurewebsites.net',
  auth0: {
    domain: 'dev-nd4gtjxtar7xqa45.us.auth0.com',
    clientId: 'wkexk8QjYX0MtuKPb0qVVFrUI93S6so9',
    redirectUri: window.location.origin,
    audience: 'https://api.dev.ivicongroup.com'
  },
  blobUrl: 'https://campimage.blob.core.windows.net',
  mapid: '3ed250267a97de49'
};
